import type { FC } from 'react';
import { useRouter } from 'next/router';
import cl from 'clsx';
import { Avatar, Button, Container, Dropdown, Text } from '@corellium/metal';
import Link from 'next/link';

import { format, parseISO } from 'date-fns';
import { useNotifications } from 'hooks/useNotifications';
import { CloudAdminLogo } from 'components/CloudAdminLogo';
import useUser from 'hooks/useUser';
import { BellIcon, BellOffIcon, LogOutIcon, MenuIcon } from 'lucide-react';
import { ThemeToggle } from 'components/ThemeToggle';

const pages = [
  {
    label: 'Metrics',
    href: '/metrics',
  },
  {
    label: 'Nodes',
    href: '/nodes',
  },
  {
    label: 'Domains',
    href: '/domains',
  },
  {
    label: 'Trial Requests',
    href: '/trials',
  },
];

const dropdownMenu = [
  {
    items: pages.map(({ href, label }) => ({
      element: <Link href={href}>{label}</Link>,
    })),
  },
];

export const Header: FC = () => {
  const { asPath } = useRouter();
  const { user, logout } = useUser();
  const { data } = useNotifications();

  let notifications = data?.map((notification) => ({
    items: [
      {
        element: (
          <div className="flex flex-col !items-start gap-1">
            <Text
              weight="medium"
              size="sm"
              className={cl('break-anywhere text-foreground')}
            >
              {notification.message}
            </Text>
            <Text size="xs" className="text-muted-foreground">
              {format(parseISO(notification.createdAt), 'MMM d, yyyy')}
            </Text>
          </div>
        ),
      },
    ],
  }));

  const emptyNotifications = !notifications?.length;

  if (emptyNotifications) {
    notifications = [
      {
        items: [
          {
            element: (
              <div className="flex flex-col gap-1">
                <Text size="sm" weight="semibold">
                  Notifications
                </Text>
                <Text size="sm" className="text-muted-foreground">
                  No notifications found
                </Text>
              </div>
            ),
          },
        ],
      },
    ];
  }

  const userMenu = [
    {
      items: [
        {
          element: <ThemeToggle />,
        },
      ],
    },
    {
      items: [
        {
          icon: <LogOutIcon />,
          label: 'Logout',
          onClick: logout,
        },
      ],
    },
  ];

  return (
    <div className="flex h-[4.5rem] items-center border-b border-border bg-background">
      <Container size="wide">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <CloudAdminLogo />
            <div className="hidden items-center gap-1 sm:flex">
              {pages.map((item, index) => {
                if (!item.href) return;

                return (
                  <Button
                    key={index}
                    variant="secondary"
                    className={cl(
                      '!shadow-none focus-visible:!shadow-focus',
                      asPath.includes(item.href) && '!bg-muted'
                    )}
                    asChild
                  >
                    <Link href={item.href}>{item.label}</Link>
                  </Button>
                );
              })}
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="hidden md:block">
              <Text size="sm" weight="medium" className="text-muted-foreground">
                {user?.domain.name}
              </Text>
            </div>
            <Dropdown
              items={notifications || []}
              className="max-h-[70vh] overflow-y-auto sm:w-[36rem]"
            >
              <Button
                variant="link"
                iconLeft={
                  emptyNotifications ? (
                    <BellOffIcon className="text-muted-foreground" />
                  ) : (
                    <BellIcon className="text-muted-foreground" />
                  )
                }
              />
            </Dropdown>
            <Dropdown items={userMenu}>
              <Button variant="link" className="!rounded-full !no-underline">
                <Avatar name={user?.user.label} />
              </Button>
            </Dropdown>
            <div className="flex sm:hidden">
              <Dropdown items={dropdownMenu}>
                <Button variant="secondary" iconLeft={<MenuIcon />} />
              </Dropdown>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
